/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.unevenCol {
  background-color: #f5f5f5;
}

.electricCell {
  background-image: url('assets/bolt_green.png');
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: left;
  padding-left: 17px;
}

.compare-table {
  display: block;
  margin: 10px 0px 10px 10px;
  max-height: 90vh;
  overflow: auto;
}

.compare-map {
  margin: 10px 10px 10px 0px;
  max-height: 90vh;
}

.compare-close-button {
  margin: 10px 0 10px 30vh;
}

.cdk-overlay-pane,
.mat-mdc-dialog-surface,
.mat-mdc-dialog-container,
.mat-mdc-dialog-inner-container {
  height: fit-content !important;
}
